.root {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    position: relative;
    flex: 1 0 auto;
    padding-top: 28px;

    &.signup {
      background: url("../../assets/images/Signup/background.png") no-repeat;
      background-size: cover;
    }
  }

  &__container {
    height: 100%;
  }

  &__footer,
  &__header {
    position: relative;
    z-index: 2;
    flex-shrink: 0;
  }

}

@media (max-width: 700px) {
  .root {
    &__details {
      display: none;
    }

    &__content {
      padding-top: 20px;
    }

  }
}
